import { EventEmitter } from 'events';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';

import { calculator } from '@autofidev/finance';

import { OfferType, offerTypes } from '@client/types';
import type { Offer } from '@client/types';
import { connect, LOAN_APP_DEFAULT, LOAN_APP_DESKING } from '@client/loanAppStore';
import { setApplicantIdentity, setFinanceType, setZipcode } from '@client/loanAppStore/updates';
import { getZipcode, getCountry } from '@client/selectors/loanApplication';
import { updateLoanApp } from '@client/lib/api';
import { n100 } from '@root/lib/helpers/format';
import { formatMessage, formatRebateMessage } from '@public/js/loan-application/utils';
import createBreakdown from '@client/lib/breakdown';
import { override } from '@client/lib/helpers/urlOverrides';
import { createSaveEstimatePayload } from '@root/lib/helpers/estimates';
import Estimator from '@client/lib/Estimator';
import { ERROR } from '@client/lib/Estimator/constants';
import {
	getCreditBandPreset,
	getTermMonthsPreset,
	getDownPaymentPreset,
	getAnnualMilesPreset,
} from './defaults-helper';
import { handleMutations } from './mutations';
// eslint-disable-next-line import/no-named-as-default
import EstimateContext from './context';
import type { Account, InstantCashOfferWithVehicle, LoanApplicationDetailed } from '@client/graphql/generated';
import { GetLoanApplicationWithDeliveryFeeDocument, RequestedOfferType } from '@client/graphql/generated';
import type { EstimateEventEmitter } from './types';
import { getDealmaker } from '@client/selectors/accountUserExperience';
import type LoanAppModel from '@client/lib/models/loanapp-model';
import client from '../../graphql/client';

type OwnProps = {
	account?: Account;
	country: string;
	financeType: OfferType;
	loanAppModel: any;
	persistLoanApp?: boolean;
	smartOffer?: any;
	storeKey?: string;
	updateApplicant: (...args: any[]) => any;
	updateFinanceType: (...args: any[]) => any;
	updateZipcode: (...args: any[]) => any;
	zipcode?: string;
};

type State = any;

type Props = OwnProps & typeof EstimateProvider.defaultProps;

export class EstimateProvider extends Component<Props, State> {
	static defaultProps = {
		persistLoanApp: true,
		storeKey: LOAN_APP_DEFAULT,
	};

	estimators: { [key in OfferType]?: Estimator } = {};

	eventEmitter: EstimateEventEmitter;

	hasCompletedFirstEstimation: boolean;

	hasUserCustomizedDownPayment: boolean;

	lastAddress: object;

	lastExemption: any;

	lastIsLocked: boolean;

	lastPricePlan: any;

	lastDiscounts: any;

	lastRebates: any;

	lastTradeIn: object;

	maxCashDowns: { [key in OfferType]?: any };

	unavailableFinanceTypes: OfferType[];

	constructor(props: Props) {
		super(props);

		this.eventEmitter = new EventEmitter();

		const { country, financeType: requestedOfferType, loanAppModel, persistLoanApp, smartOffer, zipcode } = this.props;
		const typeToLower = requestedOfferType.toLowerCase();
		const { rebatesOffered, incentiveOption } = loanAppModel;
		// if we don't persist the loanApp
		// the initial estimate is not required
		this.hasCompletedFirstEstimation = !persistLoanApp;

		// populate our totally swell financeType data containers with some
		// sane presets mocking (mostly) an estimator response
		offerTypes.forEach((type) => {
			this.estimators[type] = new Estimator({
				offerType: type,
				data: {},
				offer: {},
				constructedLoanApp: loanAppModel.data,
				loanAppModel,
			});
		});

		if (smartOffer) {
			this.estimators[typeToLower as OfferType].smartOffer = smartOffer;
		}

		const financeTypes = this.getInitialFinanceTypes();
		this.unavailableFinanceTypes = difference(offerTypes, financeTypes);

		// The loanAppModel's default `data.requestedOfferType` may not be
		// available on this vehicle. So we try to use the default type, and fall
		// back to an available type
		const financeType = financeTypes.includes(requestedOfferType) ? requestedOfferType : financeTypes[0];

		if (requestedOfferType !== financeType) {
			this.props.updateFinanceType(financeType);
		}

		const paymentInterval = loanAppModel.requestedPaymentInterval;
		const { terms } = loanAppModel;
		const offersData = {
			lease: {
				annualMiles: getAnnualMilesPreset(loanAppModel, 'lease', country),
				creditBand: getCreditBandPreset(loanAppModel, 'lease'),
				downPayment: getDownPaymentPreset(loanAppModel, 'lease'),
				termMonths: getTermMonthsPreset(loanAppModel, 'lease'),
				// TODO: Remove zipcode
				zipcode,
				monthlyPayment: 0,
				rebatesOffered,
				disabled: terms.disabled === true,
			},
			finance: {
				creditBand: getCreditBandPreset(loanAppModel, 'finance'),
				downPayment: getDownPaymentPreset(loanAppModel, 'finance'),
				termMonths: getTermMonthsPreset(loanAppModel, 'finance'),
				// TODO: Remove zipcode
				zipcode,
				monthlyPayment: 0,
				rebatesOffered,
				incentiveOption,
				disabled: terms.disabled === true,
			},
			cash: {
				downPayment: 0,
				incentiveOption: 'LARGEST_REBATE',
				rebatesOffered,
				// TODO: Remove zipcode
				zipcode,
			},
		};

		// @ts-expect-error TODO: Property 'creditBand' does not exist on type...
		let { creditBand } = offersData[financeType as OfferType];
		if (!creditBand) {
			// creditBand is undefined if financeType is cash, so fallback to finance
			creditBand = offersData.finance.creditBand;
		}

		// Keeping the state flat to represent component data needs -
		// hoping it helps with reducing picking data from the tree....
		// needs muscle when pulling data from backend to update the state
		// and update data when changing state via component interaction
		this.state = {
			// Configuration state
			financeTypes,
			paymentInterval,
			creditBand,
			downPayment: offersData[financeType as OfferType].downPayment,
			// @ts-expect-error TODO: Property 'termMonths' does not exist on type...
			termMonths: offersData[financeType as OfferType].termMonths,
			incentiveOption: offersData.finance.incentiveOption,
			annualMiles: offersData.lease.annualMiles,
			tradeIn: {
				amount: loanAppModel.tradeIn.amount,
				payoff: loanAppModel.tradeIn.payoff,
			},
			// Current offers
			offersData,
			estimate: {},
			// Estimate status
			refetchEstimate: false,
			isBalancingCashDown: false,
			loading: false,
			error: {
				finance: null,
				lease: null,
				cash: null,
			},
			isPersistingLoanApp: false,
		};

		this.hasUserCustomizedDownPayment = false;
		this.maxCashDowns = {};
	}

	// Use flags to track whether changes have been made that invalidate the
	// current estimate for each type
	shouldFetchNewEstimateDict = {
		finance: false,
		lease: false,
		cash: false,
	};

	getAlertMsg = (lastTradeIn: any, tradeIn: any) => {
		if (tradeIn.isApplied && tradeIn.amount !== 0) {
			return formatMessage('tradein/alert/tradeInAdded');
		}
		if (lastTradeIn.isApplied && !tradeIn.isApplied) {
			return formatMessage('tradein/alert/removed');
		}
	};

	getUIStateForType = (financeType: OfferType) => ({
		...pick(this.state.offersData[financeType], ['creditBand', 'downPayment', 'termMonths']),
		...pick(this.state, 'incentiveOption', 'annualMiles', 'paymentInterval'),
	});

	getUIState = () => {
		const { financeType } = this.props;
		return this.getUIStateForType(financeType as OfferType);
	};

	getMinCashDown = () => {
		const { loanAppModel } = this.props;

		if (loanAppModel.isLocked) {
			// if the deal has been locked, we check if the dealer edited the downPayment
			if (loanAppModel.dealerOverrides.downPayment) {
				// this dealer edited downPayment become the new minDownPayment
				return loanAppModel.dealerOverrides.downPayment;
			}
		}

		return 0;
	};

	getMaxCashDown = (financeType = this.props.financeType) => {
		if (this.maxCashDowns[financeType as OfferType]) {
			return this.maxCashDowns[financeType as OfferType];
		}
		const { smartOffer } = this.estimators[financeType as OfferType];
		const { loanAppModel } = this.props;
		const { estimate } = this.state;
		const vehiclePrice = smartOffer.dealerRetailPrice;
		// TODO: I'm pretty sure that `estimate` here is not actually used, we need
		// to get rebates another way
		const rebates = get(estimate, 'offer.totalRebates', 0);
		const discounts = loanAppModel.totalDiscounts;
		const { tradeInAmount } = loanAppModel;
		const inclusivePrice = get(smartOffer, 'offer.maxDownPayment', vehiclePrice - rebates - discounts - tradeInAmount);

		return n100(inclusivePrice);
	};

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'constructedLoanApp' implicitly has an '
	getUpdatedTaxesAndFees = (constructedLoanApp, loanAppModel, offer) => {
		const taxesAndFees = constructedLoanApp.taxesAndFees || [];
		const { requestedOfferType } = constructedLoanApp;
		const loanApp = {
			...loanAppModel.data,
			requestedOfferType,
			taxesAndFees,
		};
		return calculator.getUpdatedTaxesAndFees(loanApp, offer);
	};

	getInitialFinanceTypes = () => {
		const { loanAppModel, account } = this.props;
		// Trim available finance types down to just the ones we will show
		const availableFinanceTypes = loanAppModel
			// Exclude lease on used vehicles and exclude cash based on emergency dealer setting
			.availableFinanceTypes()
			// Remove offer types explicitly marked as disabled for this deal
			// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
			.filter((type) => !loanAppModel.getExpressCheckoutPreferences(type).disabled);

		// getDealmaker will return the default value if the account is not set
		const { showCashTab } = getDealmaker(account?.userExperience);
		return showCashTab
			? availableFinanceTypes
			: availableFinanceTypes.filter((type: OfferType) => type !== OfferType.Cash);
	};

	// handle return of initial estimator call/s
	// @return Promise
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'estimate' implicitly has an 'any' type.
	setEstimates = (estimate) => {
		this.setState((prevState: State) => {
			const { financeType, loanAppModel } = this.props;

			if (!estimate) {
				return Promise.resolve({});
			}

			const offersData = {
				...prevState.offersData,
				selectedOfferType: financeType,
			};

			const type = estimate.offerType.toLowerCase();

			if (type === 'lease') {
				estimate.downPayment = estimate.totalCash;
			}

			this.estimators[type as OfferType].setSmartOffer({ ...estimate, loanAppModel });

			const { smartOffer } = this.estimators[type as OfferType];
			const {
				baseMonthlyPayment,
				baseBiweeklyPayment,
				biweeklyPayment,
				biweeklySalesTax,
				downPayment,
				monthlyPayment,
				monthlySalesTax,
			} = smartOffer;
			const { termOptions = [], mileageOptions = [] } = estimate.validOptions;

			offersData[type] = {
				...offersData[type],
				baseMonthlyPayment,
				monthlyPayment,
				monthlySalesTax,
				baseBiweeklyPayment,
				biweeklyPayment,
				biweeklySalesTax,
				downPayment,
				rebatesOffered: smartOffer.rebatesOffered,
				disabled: estimate.disabled,
				mileageOptions,
				termOptions,
				estimate: {
					...estimate.data,
				},
				offer: { ...estimate.offer, ...estimate.data },
				// TODO: Why do we save `constructedLoanApp` here? This is already
				// available in `this.smart[type]`
				constructedLoanApp: estimate.constructedLoanApp,
			};

			const nextState = {
				...prevState,
				offersData,
				error: {
					...this.state.error,
					[type]: null,
				},
			};

			// Get rebates etc so we can filter for private offers.
			const { rebatesOffered, applicant } = estimate.constructedLoanApp;
			const { isPrivateOffersPathway } = loanAppModel.data;

			// Check if any private offers exist.
			const privateOffers = rebatesOffered.filter((re: any) => re.metadata && re.metadata.isPrivateOffer);

			// If we have private offers, and this isn't a private offers pathway, show highlight.
			if (!isPrivateOffersPathway && !prevState.privateOffersMsg && privateOffers.length > 0) {
				nextState.alertMsg = formatMessage('private-offer/ford-private-offer-found-for', {
					givenName: applicant.name.first,
					surName: applicant.name.last,
				});

				// Show only once.
				nextState.privateOffersMsg = true;
			}

			return nextState;
		});
	};

	finishedLoading = () => new Promise((r: any) => this.setState({ loading: false }, r));

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'alertMsg' implicitly has an 'any' type.
	showNotification = (alertMsg, alertDuration, handleAlertClick) =>
		this.setState({ alertMsg, alertDuration, handleAlertClick });

	scrollToAxzd = () => {
		const $el = document.getElementById('personalize');

		if ($el) {
			$el.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}

		this.setState({ alertMsg: null });
	};

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'callback' implicitly has an 'any' type.
	removeNotification = (callback) => this.setState({ alertMsg: null }, callback);

	fetchEstimate = (forFinanceType = this.props.financeType): Promise<Offer | {}> => {
		this.eventEmitter.emit('preparingUpdate');

		const { loanAppModel } = this.props;
		const { tradeIn } = loanAppModel;

		// This technique forces an update to rebatesOffered on the loanApp in order
		// to update the `isSelected` field on each rebate. This is necessary so
		// that Estimator considers the rebates when returning an offer
		// TODO: There's probably a better way to do this that communicates the
		// nature of the update better.
		// TODO: This can just be done in `setEstimates`
		const rebatesOffered = get(this.state.offersData, 'rebatesOffered', loanAppModel.rebatesOffered);
		loanAppModel.setAttribute('rebatesOffered', rebatesOffered);

		const {
			termMonths,
			creditBand = this.state.offersData.finance.creditBand,
			downPayment,
			incentiveOption,
			annualMiles,
			paymentInterval,
		} = this.getUIStateForType(forFinanceType);

		return this.estimators[forFinanceType].fetch(loanAppModel, {
			annualMiles,
			creditBand,
			downPayment,
			incentiveOption,
			paymentInterval,
			rebatesOffered,
			termMonths,
			tradeIn,
		});
	};

	/** Sets an empty object {} or real ICO with vehicle */
	setICO = (instantCashOffer: InstantCashOfferWithVehicle) => {
		const { loanAppModel } = this.props;
		if (instantCashOffer) {
			loanAppModel.tradeIn = {
				...loanAppModel.tradeIn,
				instantCashOffer,
			};
		}
	};

	setLoanAppData = (newLoanApp: LoanApplicationDetailed) => {
		const { loanAppModel } = this.props;
		const { instantCashOffer } = loanAppModel.tradeIn;

		if (newLoanApp) {
			loanAppModel.data = newLoanApp;
			this.setICO(instantCashOffer);
		}

		return Promise.resolve();
	};

	// get initial estimates for all offer types
	// returns a promise which resolves when initial estimate data is available
	getInitialEstimates = async () => {
		const { financeType, zipcode } = this.props;
		this.setState({ loading: true });
		await Promise.all(
			offerTypes.map(async (type) => {
				const isCurrentType = type === financeType;

				// Save estimate for retryTermOptions.
				let estimate;
				try {
					estimate = await this.fetchEstimate(type);
					this.validateEstimate(estimate);

					if (isCurrentType) {
						this.updateLoanAppAttributes(estimate);
					}

					this.setEstimates(estimate);

					if (zipcode) {
						await this.getLoanApplicationTaxesAndFeesWithDistanceFee(estimate);
					}
					if (isCurrentType) {
						await this.persistLoanApp(false, type);
					}
				} catch (err) {
					const canRetry = this.retryTermOptions(err, type, estimate);
					if (!isCurrentType || !canRetry) {
						this.updateTabs(type === OfferType.Lease);
						this.handleEstimateErr(type);
					} else {
						this.shouldFetchNewEstimateDict[type] = true;
						this.setState({ refetchEstimate: true });
					}
				}
			})
		);

		await this.finishedLoading();
		this.hasCompletedFirstEstimation = true;
	};

	// Select new termMonths for estimate retry. Return true if retry needed.
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'err' implicitly has an 'any' type.
	retryTermOptions = (err, type, estimate) => {
		const { termMonths = 72 } = this.state.offersData[type];
		let { termOptions } = this.state.offersData[type];

		// If termOptions is undefined, try to get them from response.
		if (!termOptions || (termOptions && !termOptions.length)) {
			termOptions = estimate && estimate.validOptions ? [...estimate.validOptions.termOptions] : undefined;
		}

		// Only retry for a valid reason, or if termOptions is undefined.
		if (!err || type === 'cash' || type !== this.props.financeType || !termOptions || !termOptions.length) {
			// Can't retry.
			return false;
		}

		const termOptionsClone = [...termOptions];

		// Find the next smallest term option.
		const newTermMonths = termOptionsClone.reverse().find((term) => term < termMonths);

		if (newTermMonths) {
			const offersData = {
				...this.state.offersData,
			};

			// Save new termMonths value and disabled options for retry.
			offersData[type].termMonths = newTermMonths;

			this.setState({
				offersData,
				termMonths: newTermMonths,
			});

			// A new termMonths value is available to be retried.
			return true;
		}

		// Can't retry.
		return false;
	};

	updateTabs = (leaseError = false) => {
		const { loanAppModel, account } = this.props;
		let { financeType } = this.props;
		const { showCashTab } = getDealmaker(account?.userExperience);

		// Unless overridden by dealer or account settings, cash tab is always visible
		const financeTabs = loanAppModel.availableFinanceTypes().filter((t: OfferType) => {
			// Remove Cash if showCashTab is false
			if ((!showCashTab && t === OfferType.Cash) || (t === OfferType.Lease && leaseError)) {
				return false;
			}
			return true;
		});

		// check if the current finance type has gotten some results
		// if not fall back to 'finance' or the first element of remaining available financeTypes
		if (!financeTabs.includes(financeType)) {
			// eslint-disable-next-line prefer-destructuring
			financeType = financeTabs[0];
		}

		this.props.updateFinanceType(financeType);
		this.setState({
			financeTypes: financeTabs,
			termMonths: getTermMonthsPreset(loanAppModel, financeType) ?? 0,
		});
	};

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'estimate' implicitly has an 'any' type.
	updateLoanAppAttributes = (estimate) => {
		const { financeType, loanAppModel } = this.props;
		const { constructedLoanApp, offer, offerType } = estimate;

		if (offerType.toLowerCase() !== financeType) {
			return;
		}

		if (!constructedLoanApp) {
			return;
		}

		const { bookoutData } = constructedLoanApp;

		loanAppModel.setAttribute('bookoutData', bookoutData);

		// other components need the latest loanAppModel.taxesAndFees during re-render
		const taxesAndFees = this.getUpdatedTaxesAndFees(constructedLoanApp, loanAppModel, offer);
		loanAppModel.setAttribute('taxesAndFees', taxesAndFees);
	};

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
	validateEstimate = (result) => {
		const type = result.offerType.toLowerCase();

		if (result.error) {
			if (result.error.message === ERROR.OFFER_TYPE_UNAVAILABLE) {
				this.unavailableFinanceTypes.push(type);
			}
			throw result.error;
		}

		const isDisabled = get(result, 'data.disabled', false);
		// We only check if we have term options for offers that are not cash
		const hasTermOptions = type === 'cash' || get(result, 'validOptions.termOptions.length', 0) !== 0;

		if ((isDisabled || !hasTermOptions) && !this.unavailableFinanceTypes.includes(type)) {
			this.unavailableFinanceTypes.push(type);
			throw new Error(ERROR.INVALID_TERM_OPTIONS);
		}
	};

	createPayload = (offerType: OfferType, apply: boolean) => {
		try {
			const { incentiveOption, offersData } = this.state;
			const offerData = offersData[offerType];
			const { constructedLoanApp, offer } = offerData;
			const data = offerData.estimate;
			const { creditBand } = offerData;
			const payload = createSaveEstimatePayload(
				this.props.loanAppModel.data,
				{
					constructedLoanApp,
					data,
					offer,
					offerType: offerType.toUpperCase(),
				},
				{ incentiveOption, creditBand }
			);

			// @ts-expect-error ts-migrate(2339) FIXME: Property 'isInStore' does not exist on type 'Objec
			payload.isInStore = override.isEnabled('af-instore') || payload.isInStore;
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'apply' does not exist on type 'Object'.
			payload.apply = apply;

			return payload;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error creating payload', error);
		}
	};

	persistLoanApp = (apply = true, financeType: OfferType) => {
		financeType = financeType || this.props.financeType;
		const { persistLoanApp = true } = this.props;
		const payload = this.createPayload(financeType, apply);
		const { error } = this.state;
		const { termMonths } = this.getUIStateForType(financeType);

		// In some cases (like dealer edit) we don't want to persist changes to the
		// server, but just see estimates
		// Also don't persist the app if there is no payload for this finance type
		// If the financeType is with the previous state
		// We should not call the estimate endpoint.
		const shouldNotPersistChange =
			financeType !== window.autofi?.data?.requestedOfferType?.toLowerCase() &&
			this.state.requestedOfferType !== undefined &&
			financeType !== this.state.requestedOfferType;

		if (error[financeType] || this.state.isPersistingLoanApp || shouldNotPersistChange || !persistLoanApp || !payload) {
			return Promise.resolve();
		}

		this.setState((prevState: State) => ({ ...prevState, termMonths, isPersistingLoanApp: true }));

		return updateLoanApp(this.props.loanAppModel.id, {
			payload,
			stats: {}, // TODO: stats
			step: 'estimate',
		})
			.then(({ newLoanApp }) => {
				if (this.props.financeType === financeType) {
					setTimeout(() => this.eventEmitter.emit('updated', newLoanApp));
					this.setLoanAppData(newLoanApp);
				}
			})
			.catch((err) => {
				// TODO: error handling

				// eslint-disable-next-line no-console
				console.log(err);
			})
			.finally(() => {
				this.setState((prevState: State) => ({ ...prevState, isPersistingLoanApp: false }));
			});
	};

	/* EVENT HANDLERS */
	setFinanceType = (financeType: OfferType) => {
		const { loanAppModel } = this.props;
		const { creditBand: desiredCreditBand, downPayment: desiredDownPayment, isPersistingLoanApp } = this.state;

		// Change the tab
		this.props.updateFinanceType(financeType);
		if (isPersistingLoanApp) {
			this.setState((prevState: State) => ({ ...prevState, isPersistingLoanApp: false }));
		}

		// copy the selected Special rebates from previous offer
		const currentSelectedSpecialRebatesIds = (loanAppModel.selectedDirectOfferIds || []).map((r: any) => r.toString());

		const offer = { ...this.state.offersData[financeType] };
		offer.rebatesOffered
			// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'r' implicitly has an 'any' type.
			.filter((r) => r.isQualifiedOffer)
			// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rebate' implicitly has an 'any' type.
			.forEach((rebate) => {
				rebate.isSelected = currentSelectedSpecialRebatesIds.includes(rebate.programId.toString());
			});

		// Update the creditBand of the offer we're going to
		offer.creditBand = desiredCreditBand;

		// Update the down payment of the offer we're going to
		if (this.hasUserCustomizedDownPayment) {
			if (desiredDownPayment > this.getMaxCashDown(financeType)) {
				// Handle the case where downPayment is too large for this finance type
				offer.downPayment = this.getMaxCashDown(financeType);
			} else {
				offer.downPayment = desiredDownPayment;
			}
		}

		const offersData = {
			...this.state.offersData,
			[financeType]: offer,
			selectedOfferType: financeType,
		};

		this.setState({
			// tradeIn is complete so refetch estimate in case isApplied needs to
			// be updated because the newly selected offer type can't apply it
			refetchEstimate: loanAppModel.tradeIn.complete,
			requestedOfferType: financeType,
			offersData,
		});
	};

	refetchEstimate = () => {
		this.setState({ refetchEstimate: true });
	};

	/**
	 * Persists address change for the passed loanapp
	 *
	 * @param {Object} loanAppModel
	 * @returns {Promise<any>}
	 */
	updateLoanAppAddress = (loanAppModel: LoanAppModel) =>
		updateLoanApp(loanAppModel.id, {
			step: 'updateApplicantAddress',
			payload: {
				address: loanAppModel.applicant.address,
				isTaxExempt: !!loanAppModel.applicant.isTaxExempt,
			},
		});

	/**
	 * Handles an array of state changes and/or loanApp mutations
	 * Then trigger a fetchEstimate if necessary
	 *
	 * @param {import('./mutations').MutationCommand[]} mutations
	 * @returns {boolean}
	 */
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'mutations' implicitly has an 'any' type
	handleChanges = (mutations) => {
		const { financeType, loanAppModel } = this.props;
		// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'type' implicitly has an 'any' typ
		if (mutations.some(([type]) => type === 'downPayment')) {
			// specific case for DownPayment
			// TODO: might want to move that to mutations.ts
			this.hasUserCustomizedDownPayment = true;
		}

		const newState = handleMutations({
			financeType,
			loanAppModel,
			currentState: this.state,
		})(mutations);

		return new Promise((resolve: any) => {
			if (newState) {
				return this.setState(newState, resolve);
			}

			resolve();
		});
	};

	/**
	 * Helper for handleChanges to handle a unique change/mutation
	 * TODO: Change type signature to use `MutationCommand`
	 *
	 * @param {RowType} type
	 * @param {any} value
	 */
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
	handleChange = (type, value) => this.handleChanges([[type, value]]);

	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'applicant' implicitly has an 'any' type
	handleApplicantUpdate = (applicant) => {
		const { loanAppModel } = this.props;
		const isChangeInExemption = applicant.isTaxExempt !== this.lastExemption;
		const isChangeInAddress = !isEqual(applicant.address, this.lastAddress);

		if ((isChangeInExemption || isChangeInAddress) && !loanAppModel.isStandAlone) {
			this.refetchEstimate();
		}
	};

	getLoanApplicationTaxesAndFeesWithDistanceFee = async (estimate: any) => {
		try {
			const { loanAppModel } = this.props;
			const {
				data: loanApplication,
				urlToken,
				applicant: { address },
				requestedOfferType,
			} = loanAppModel;
			const dealerFees = get(loanApplication, 'dealer.settings.fees.dealer', []);
			const isCashOfferType = requestedOfferType === RequestedOfferType.Cash;
			const isDealerWithDistanceFee = dealerFees.some(
				(fee: { feeType: string; offerType: any }) =>
					fee?.feeType === 'DISTANCE' &&
					(fee?.offerType === requestedOfferType || (fee?.offerType === RequestedOfferType.Finance && isCashOfferType))
			);
			if (isDealerWithDistanceFee) {
				const { data } = await client.query({
					query: GetLoanApplicationWithDeliveryFeeDocument,
					fetchPolicy: 'no-cache',
					variables: {
						input: {
							address: {
								street: address?.street,
								state: address?.state,
								street2: address?.street2,
								city: address?.city,
								zip: address?.zip,
							},
							...(this.props.storeKey === LOAN_APP_DESKING && {
								taxesAndFees: estimate.constructedLoanApp.taxesAndFees,
							}),
						},
						token: urlToken,
					},
				});
				const taxesAndFees = get(data, 'loanApplicationDetailed.taxesAndFeesWithDelivery', []);
				const newEstimate = {
					...estimate,
					constructedLoanApp: {
						...estimate.constructedLoanApp,
						taxesAndFees,
					},
				};

				this.updateLoanAppAttributes(newEstimate);
				this.validateEstimate(newEstimate);
				this.setEstimates(newEstimate);
				loanAppModel.setAttribute('expressCheckout.preferences.dealerOverrides.hasFeesOverrides', true);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error getting loanapplication taxesAndFees with delivery', error);
		}
	};

	handleAddressChange = ({ zip }: { zip: string }) => {
		const offersData = { ...this.state.offersData };
		['finance', 'lease', 'cash'].forEach((type) => {
			offersData[type] = {
				...offersData[type],
				zipcode: zip,
			};
		});

		this.props.updateZipcode(zip);
		this.setState({ offersData });
	};

	handleSaveMyDeal = (applicant: any) => {
		this.props.updateApplicant(applicant);
		this.persistLoanApp(true, this.props.financeType);
		this.showNotification(formatMessage('common/deal-sent', { email: applicant?.email }), 10000, null);
	};

	handleEstimateErr = (type = this.props.financeType) => {
		this.setState(
			{
				error: {
					...this.state.error,
					[type]: formatMessage('home/alerts/error-calculating-payment'),
				},
			},
			() => {
				return Promise.resolve({});
			}
		);
	};

	// Check in componentDidUpdate if a new estimate should be fetched
	async componentDidUpdate(prevProps: Props, prevState: State) {
		const { loanAppModel, financeType } = this.props;
		const { refetchEstimate, financeTypes } = this.state;
		const { applicant, dealer, discounts, isLocked, rebatesOffered, selectedPricePlan, tradeIn } = loanAppModel;
		const isChangeInState = [
			'annualMiles',
			'creditBand',
			'downPayment',
			'incentiveOption',
			'paymentInterval',
			'termMonths',
		]
			.map((key) => prevState[key] !== this.state[key])
			.includes(true);

		// See if there's been a change in discounts, tradein, or rebates
		let alertMsg;
		let alertDuration;
		let handleAlertClick;

		if (this.lastIsLocked !== undefined && isLocked !== this.lastIsLocked) {
			if (isLocked) {
				// the deal just got locked
				// we ensure we got rid of all other offerTypes

				// saving previous unavailable types
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'lastUnavailableFinanceTypes' does not ex
				this.lastUnavailableFinanceTypes = [...this.unavailableFinanceTypes];
				// @ts-expect-error ts-migrate(2551) FIXME: Property 'lastFinanceTypes' does not exist on type
				this.lastFinanceTypes = financeTypes;

				this.unavailableFinanceTypes = difference(offerTypes, [financeType]);
				this.setState({ financeTypes: [financeType] });
			} else {
				// @ts-expect-error ts-migrate(2551) FIXME: Property 'lastFinanceTypes' does not exist on type
				const financeTypes = this.lastFinanceTypes || this.getInitialFinanceTypes();
				this.unavailableFinanceTypes =
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'lastUnavailableFinanceTypes' does not ex
					this.lastUnavailableFinanceTypes || difference(offerTypes, financeTypes);
				this.setState({ financeTypes });
			}
		}

		const isChangeInDiscounts = !isEqual(discounts, this.lastDiscounts);

		if (this.lastDiscounts && isChangeInDiscounts) {
			alertMsg = formatMessage('home/discounts-updated');
		}
		// eslint-disable-next-line max-len
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'complete' does not exist on type '{} | Exclude<this["lastTradeIn"], undefined>'. Property 'complete' does not exist on type '{}'.
		const tradeInComplete = tradeIn.complete || get(this, 'lastTradeIn', {}).complete;
		const isChangeInTradeIn = !isEqual(
			omit(tradeIn, ['_id', 'isApplied']),
			omit(this.lastTradeIn, ['_id', 'isApplied'])
		);

		// TODO: When possible, move this into DM/index so EstimateProvider has one
		// less concern
		if (this.lastTradeIn && tradeInComplete && isChangeInTradeIn) {
			alertMsg = this.getAlertMsg(this.lastTradeIn, tradeIn);
		}

		// TODO: This should be in state.zip and not separate
		const isChangeInAddress = !isEqual(applicant.address, this.lastAddress);

		const isChangeInExemption = !isEqual(applicant.isTaxExempt, this.lastExemption);

		// sort rebates by programId;
		// exclude rules/disclaimers since those can get changed via formatting
		// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rebates' implicitly has an 'any' type.
		const sortRebates = (rebates) =>
			sortBy(
				// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'r' implicitly has an 'any' type.
				rebates.map((r) => omit(r, ['rules', 'disclaimer'])),
				'programId'
			);
		const lastRebates = sortRebates(get(this, 'lastRebates', []));
		const currentRebates = sortRebates(rebatesOffered);
		const isChangeInRebates = (currentRebates.length || lastRebates.length) && !isEqual(currentRebates, lastRebates);

		// TODO: When possible, move this into DM/index so EstimateProvider has one
		// less concern
		if (lastRebates.length && isChangeInRebates) {
			const { brand } = dealer._data;
			const isShiftEnabled = dealer.settings.analytics?.shiftDigital.enabled;
			const shouldShowIncentivesText = (brand === 'volkswagen' && isShiftEnabled) || ['acura', 'honda'].includes(brand);

			// TODO: use logic in clients/dealmaker/hooks/useShouldShowIncentivesText.ts
			// to avoid duplication
			alertMsg = formatRebateMessage('home/rebates-updated', {
				shouldShowIncentivesText,
				format: 'plural-capitalized',
			});
		}

		const isChangeInPricePlan =
			!isEqual(selectedPricePlan, this.lastPricePlan) && this.state.offersData[financeType].monthlyPayment;

		if (isChangeInPricePlan) {
			let pricePlanCode;
			if (selectedPricePlan) {
				pricePlanCode = selectedPricePlan.code;
				handleAlertClick = this.scrollToAxzd;
				alertDuration = 15000;
			} else {
				pricePlanCode = this.lastPricePlan.code;
			}
			pricePlanCode = pricePlanCode.split(':').pop();
			alertMsg = formatMessage(selectedPricePlan ? 'home/axzd-added' : 'home/axzd-removed', {
				pricePlanCode,
			});

			// set lastPricePlan to undefined if there's no selectedPricePlan as opposed
			// to an empty object, which differs from when selectedPricePlan is undefined
			this.lastPricePlan = selectedPricePlan ? { ...selectedPricePlan } : undefined;
		}

		// show notification if alertMsg is defined
		if (alertMsg) {
			this.showNotification(alertMsg, alertDuration, handleAlertClick);
		}

		// Save these for the next comparison
		this.lastRebates = rebatesOffered.map(cloneDeep);
		this.lastDiscounts = discounts.map(cloneDeep);
		this.lastAddress = { ...applicant.address };
		this.lastExemption = applicant.isTaxExempt;
		this.lastTradeIn = { ...tradeIn };
		this.lastIsLocked = isLocked;

		// `cDU` will be called when initial estimates are saved. Since that is the
		// first run of `cDU`...
		//   1) isCriteriaDifferent will be true because we have not perform the
		//      check before
		//   2) we don't need to fetch new estimates or mark current ones as stale,
		//      as the current ones have just been completed
		//
		// We return early as we do not want to fetch new estimates
		//
		// We do not return earlier as we still want to save `this.lastRebates` etc.
		// for comparison with the next set of changes
		if (!this.hasCompletedFirstEstimation && !refetchEstimate) {
			return;
		}

		const isCriteriaDifferent =
			refetchEstimate ||
			isChangeInRebates ||
			isChangeInDiscounts ||
			isChangeInTradeIn ||
			isChangeInAddress ||
			isChangeInExemption ||
			isChangeInState;

		// Mark estimate for current finance type as fresh, and mark the estimates
		// for other finance types as stale
		if (isCriteriaDifferent) {
			Object.keys(this.shouldFetchNewEstimateDict).forEach((key) => {
				const isEstimateStale = key !== financeType;
				// @ts-expect-error ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin
				this.shouldFetchNewEstimateDict[key] = isEstimateStale;
			});
		}

		const isFinanceTypeChanged = financeType !== prevProps.financeType;
		const isCurrentEstimateStale = this.shouldFetchNewEstimateDict[financeType];
		const shouldFetchEstimates = isCurrentEstimateStale || isCriteriaDifferent;

		if (isFinanceTypeChanged && !shouldFetchEstimates) {
			// @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
			this.persistLoanApp(false);
		}

		if (shouldFetchEstimates) {
			this.shouldFetchNewEstimateDict[financeType] = false;
			this.setState({ loading: true, refetchEstimate: false });

			let estimate;
			try {
				estimate = await this.fetchEstimate(financeType);
				this.updateLoanAppAttributes(estimate);
				this.validateEstimate(estimate);
				this.setEstimates(estimate);
				await this.persistLoanApp(false, financeType);
				if (this.props.zipcode) {
					await this.getLoanApplicationTaxesAndFeesWithDistanceFee(estimate);
				}
				this.finishedLoading();
			} catch (err) {
				const canRetry = this.retryTermOptions(err, financeType, estimate);
				if (!canRetry) {
					this.updateTabs(financeType === OfferType.Lease);
					this.handleEstimateErr();
					this.finishedLoading();
				} else {
					this.shouldFetchNewEstimateDict[financeType] = true;
					this.setState({ refetchEstimate: true });
				}
			}
		}
	}

	render() {
		const { loanAppModel, children, storeKey, financeType } = this.props;
		const {
			financeTypes,
			paymentInterval,
			// Status
			isBalancingCashDown,
			loading,
			error,
			alertMsg,
			alertDuration,
			handleAlertClick,
		} = this.state;

		const configuration = this.getUIState();
		const errorMessage = error[financeType];

		// Create a breakdown to pass down to child components
		const baseOffer = this.state.offersData[financeType];
		const { smartOffer } = this.estimators[financeType];
		const { taxesAndFees } = smartOffer.constructedLoanApp || [];
		loanAppModel.setAttribute('taxesAndFees', taxesAndFees);
		const breakdown = createBreakdown(loanAppModel, baseOffer, smartOffer);

		// TODO:
		// `paymentAmount` and other info are NaN if estimates have not been fetched
		// Maybe we should present a unified interface for getting data from smart offer
		// if available, otherwise fall back to base data

		const value = {
			financeTypes,
			loanAppModel,
			// Handlers
			getInitialEstimates: this.getInitialEstimates,
			getMaxCashDown: this.getMaxCashDown,
			getMinCashDown: this.getMinCashDown,
			handleAddressChange: this.handleAddressChange,
			handleApplicantUpdate: this.handleApplicantUpdate,
			handleChange: this.handleChange,
			handleChanges: this.handleChanges,
			handleSaveMyDeal: this.handleSaveMyDeal,
			persistLoanApp: this.persistLoanApp,
			refetchEstimate: this.refetchEstimate,
			setFinanceType: this.setFinanceType,
			// Configuration state
			financeType,
			paymentInterval,
			requestedOfferType: this.state.requestedOfferType,
			...configuration,
			// Estimate state
			errorMessage,
			isBalancingCashDown,
			isLoading: loading,
			// Estimate data
			baseOffer,
			breakdown,
			smartOffer,
			offersData: this.state.offersData,
			// TODO: REMOVE this when we migrate away from v3/cni and v3/tradein
			showNotification: this.showNotification,
			removeNotification: this.removeNotification,
			alertMsg,
			alertDuration,
			handleAlertClick,
			updateLoanAppAddress: this.updateLoanAppAddress,
			hasCompletedFirstEstimation: this.hasCompletedFirstEstimation,
			estimateEventEmitter: this.eventEmitter,
			isPersistingLoanApp: this.state.isPersistingLoanApp,
		};

		// Since there may be multiple EstimateProviders, we use a Consumer+Provider
		// To get the existing state and mix-in the state this EP is providing
		return (
			<EstimateContext.Consumer>
				{(context) => (
					<EstimateContext.Provider
						value={{
							...context,
							[storeKey]: value,
						}}
					>
						{children}
					</EstimateContext.Provider>
				)}
			</EstimateContext.Consumer>
		);
	}
}

export { EstimateConsumer } from './EstimateConsumer';

/*
 * Export prop types the child components can expect.
 *
 * Some child components which use this API aren't currently tested in Jest, so
 * this is the only place we can get test coverage ensuring the correct prop
 * types are passed down by this component.
 *
 * If desired, we can move these type definitions into child components once
 * there is test coverage of components which consume these props.
 */
export const estimateProvidedProps = {
	financeTypes: PropTypes.arrayOf(PropTypes.string),
	loanAppModel: PropTypes.object,
	// Handlers
	getInitialEstimates: PropTypes.func,
	getMaxCashDown: PropTypes.func,
	getMinCashDown: PropTypes.func,
	handleAddressChange: PropTypes.func,
	handleApplicantUpdate: PropTypes.func,
	handleChange: PropTypes.func,
	handleChanges: PropTypes.func,
	handleSaveMyDeal: PropTypes.func,
	persistLoanApp: PropTypes.func,
	refetchEstimate: PropTypes.func,
	setFinanceType: PropTypes.func,
	// Configuration state
	annualMiles: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	creditBand: PropTypes.string,
	downPayment: PropTypes.number,
	financeType: PropTypes.string,
	incentiveOption: PropTypes.string,
	paymentInterval: PropTypes.string,
	termMonths: PropTypes.number,
	// Estimate status
	isLoading: PropTypes.bool,
	error: PropTypes.shape({
		cash: PropTypes.string,
		finance: PropTypes.string,
		lease: PropTypes.string,
	}),
	isBalancingCashDown: PropTypes.bool,
	// Estimate data
	baseOffer: PropTypes.object,
	breakdown: PropTypes.shape({
		dealerRetailPrice: PropTypes.number,
		discounts: PropTypes.arrayOf(PropTypes.object),
		nonApplicableRebates: PropTypes.arrayOf(PropTypes.object),
		onlinePrice: PropTypes.number,
		rebateItems: PropTypes.arrayOf(PropTypes.object),
		tax: PropTypes.shape({
			taxesAndFees: PropTypes.arrayOf(PropTypes.object),
			taxesAndFeesList: PropTypes.arrayOf(PropTypes.object),
			totalTaxes: PropTypes.number,
		}),
		totalRebates: PropTypes.number,
		tradeIn: PropTypes.shape({
			addOns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
			amount: PropTypes.number,
			bookValue: PropTypes.number,
			complete: PropTypes.bool,
			dealerOverride: PropTypes.bool,
			raw: PropTypes.object,
			terminatingLease: PropTypes.bool,
		}),
	}),
	offersData: PropTypes.object,
	smartOffer: PropTypes.object,
	updateLoanAppAddress: PropTypes.func,
	eventEmitter: PropTypes.object,
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state) => ({
	account: state.account,
	financeType: state.requestedOfferType.toLowerCase(),
	zipcode: getZipcode(state),
	country: getCountry(state),
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'update' implicitly has an 'any' type.
const mapUpdateToProps = (update) => ({
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'financeType' implicitly has an 'any' ty
	updateFinanceType: (financeType) => update(setFinanceType(financeType)),
	// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'zip' implicitly has an 'any' type.
	updateZipcode: (zip) => update(setZipcode(zip)),
	updateApplicant: (applicant: any) => update(setApplicantIdentity(applicant)),
});

export default connect(mapStateToProps, mapUpdateToProps)(EstimateProvider);
